





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Department } from '@/store/departments'
@Component
export default class DepartmentComponent extends Vue {
  @Prop() department!: Department;
}
