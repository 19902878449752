








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SectionModule, Section } from '@/store/sections';
import SectionComponent from '@/components/SectionComponent.vue';

@Component({
  components: {
    SectionComponent
  }
})
export default class Sections extends Vue {
  private get sections(){
    return SectionModule.sections;
  }
}
