
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Matter, { IMouseConstraintDefinition } from 'matter-js'
import Ball from '@/ball'
import * as PIXI from 'pixi.js'
import { BallInformation } from '@/store/app'
@Component({
  components: {
  }
})
export default class Scene extends Vue {
  @Prop() informations!: BallInformation[];
  private balls = new Array<Ball>();
  private static app: PIXI.Application;
  private static engine: Matter.Engine;
  private static render: Matter.Render;
  private static texture: PIXI.Texture;
  private static ballsCount = 0;
  private static cw=0;
  private static ch=0;

  private created() {
    Scene.app = new PIXI.Application({
      transparent: true
    });
  }

  private mounted() {
    const pixiElement  = document.getElementById('pixi')!;
    Scene.app.resizeTo = pixiElement;
    pixiElement.appendChild(Scene.app.view);
    Scene.texture = PIXI.Texture.from('/img/ball.svg');

    const matterElement  = document.getElementById('matter')!;
    const engine = Matter.Engine.create();
    Scene.engine = engine;
    Scene.cw = matterElement.clientWidth;
    Scene.ch = matterElement.clientHeight;
    const render = Matter.Render.create({
      element: matterElement,
      engine: engine,
      options: {
        width: Scene.cw,
        height: Scene.ch,
        wireframes: false
      }
    });
    Scene.render = render;
    // engine.world.gravity.y = -0.005;
    engine.world.gravity.y = -0.15;

    // const mouseDrag = Matter.MouseConstraint.create(engine, {
    //   element: pixiElement.childNodes[0]
    // } as IMouseConstraintDefinition);
    // Matter.World.add(engine.world, mouseDrag);

    const ground = Matter.Bodies.rectangle(400, 610, 810, 60, { isStatic: true });
    const w = Scene.cw;
    const h = Scene.ch;
    Matter.World.add(engine.world, [
        // Matter.Bodies.rectangle(w/10*9, 0, w, 20, {isStatic: true, angle: Math.PI/4}),
        // Matter.Bodies.rectangle(w/10, 0, w, 20, {isStatic: true, angle: Math.PI/4*3}),
        // Matter.Bodies.rectangle(w/10*9, h, w, 20, {isStatic: true, angle: Math.PI/4*3}),
        // Matter.Bodies.rectangle(w/10, h, w, 20, {isStatic: true, angle: Math.PI/4}),
        // Matter.Bodies.rectangle(w/2, h/2, w*.6, 5, {isStatic: true}),
        Matter.Bodies.rectangle(w/2, 0, w, 20, {isStatic: true}),
        Matter.Bodies.rectangle(w, h/2, 20, h*2, {isStatic: true}),
        Matter.Bodies.rectangle(0, h/2, 20, h*2, {isStatic: true}),
        // Matter.Bodies.rectangle(w/2, h, w, 20, {isStatic: true})
    ]);
    Matter.Engine.run(engine);
    Matter.Render.run(render);

    // setTimeout(()=>{
    //   Matter.World.add(engine.world, [
    //       Matter.Bodies.rectangle(w/2, h, w, 20, {isStatic: true})
    //   ]);
    //   engine.world.gravity.y = -0.005;
    // }, 10000);

    Matter.Events.on(engine, "beforeUpdate", (ev) => {
      for(const ball of this.balls){
        if(ball)ball.applyForce(w,h);
      }
    });

    Matter.Events.on(engine, 'afterUpdate', (ev) => {
      this.drawBalls(this.balls);
    });
  }

  getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  drawBalls(balls: Ball[]){
    for(const ball of balls){
      if(ball)ball.update();
    }
  }

  beforeDestroy(){
    Scene.app.destroy(true);
    Matter.Render.stop(Scene.render);
    Matter.World.clear(Scene.engine.world, false);
    Matter.Engine.clear(Scene.engine);
  }

  @Watch('informations', {immediate: true})
  async addBalls(){
    if(this.informations == undefined) return;
    for(let i=0;i<this.informations.length;i++) {
      if(this.informations[i].imageUrl && !PIXI.Loader.shared.resources[this.informations[i].imageUrl]){
        PIXI.Loader.shared.add(this.informations[i].imageUrl);
      }
    }
    PIXI.Loader.shared.load((loader, res)=>{
      for (let i = this.informations.length - 1; i > 0; i--) {
        const r = Math.floor(Math.random() * (i + 1));
        const tmp = this.informations[i];
        this.informations[i] = this.informations[r];
        this.informations[r] = tmp;
      }

      for(let i=0;i<this.informations.length;i++) {
        const gap = 180;
        const x = gap*(i%(Scene.cw/gap-1));
        const y = gap*(i/(Scene.cw/gap-1));
        this.balls[i] = (new Ball(this.informations[i].title, this.informations[i].path, Scene.app.stage, Scene.engine.world, Scene.texture, x, y, this.informations[i].count, res[this.informations[i].imageUrl] ? res[this.informations[i].imageUrl]!.texture : undefined));
      }
    });
  }
}
