





















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Section } from '@/store/sections'
@Component
export default class SectionComponent extends Vue {
  @Prop() section!: Section;
}
