import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';

export interface Project {
  id: number;
  title: string;
  content: string;
  excerpt: string;
  departments: Department[];
  labs: Lab[];
  sections: Section[];
  mainThemes: MainTheme[];
  projectTags: ProjectTag[];
  eyecatch: string;
  squareEyecatch: string;
  creators: Creator[];
  terms: Term[];
  pageviews: number;
}
export interface Projects {
  projects: Project[];
}

export interface Creator {
  creator_name: string;
  creator_profile: string;
}

export interface MainTheme{
  name: string;
  link: string;
}

export interface ProjectTag{
  name: string;
  link: string;
}

export interface Lab {
  name: string;
  link: string;
}

export interface Department {
  name: string;
  link: string;
}

export interface Section {
  name: string;
  link: string;
}

export interface Term {
  name: string;
}

@Module({ dynamic: true, store, name: 'projectstore' })
class ProjectStore extends VuexModule implements Projects {
  public projects = [] as Project[];
  public isLoading = false;

  @Action
  public setLoading(isLoading: boolean) {
    this.SET_LOADING(isLoading);
  }

  @Mutation
  public SET_LOADING(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Action
  public setProjects(projects: Project[]) {
    this.SET_PROJECTS(projects);
  }

  @Mutation
  private SET_PROJECTS(projects: Project[]) {
    this.projects = projects;
  }

  @Action
  public clearProjects() {
    this.CLEAR_PROJECTS();
  }

  @Mutation
  private CLEAR_PROJECTS() {
    this.projects = [];
  }

  @Action
  public appendProjects(projects: Project[]) {
    this.APPEND_PROJECTS(projects);
  }

  @Mutation
  private APPEND_PROJECTS(projects: Project[]) {
    this.projects = this.projects.concat(projects);
  }

}
export const ProjectModule = getModule(ProjectStore);
