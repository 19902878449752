








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DepartmentModule, Department } from '@/store/departments';
import DepartmentComponent from '@/components/DepartmentComponent.vue';

@Component({
  components: {
    DepartmentComponent
  }
})
export default class Departments extends Vue {
  private get departments(){
    return DepartmentModule.departments;
  }
}
