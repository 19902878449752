import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';

export interface Department {
  id: number;
  name: string;
  description: string;
  eyecatch: string;
  movie: string;
}
export interface Departments {
  departments: Department[];
}


@Module({ dynamic: true, store, name: 'departmentstore' })
class DepartmentStore extends VuexModule implements Departments {
  public departments = [] as Department[];

  @Action
  public setDepartment(departments: Department[]) {
    this.SET_DEPARTMENTS(departments);
  }

  @Mutation
  private SET_DEPARTMENTS(departments: Department[]) {
    this.departments = departments;
  }

}
export const DepartmentModule = getModule(DepartmentStore);
