import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';

export interface Section {
  id: number;
  name: string;
  description: string;
  eyecatch: string;
  movie: string;
}
export interface Sections {
  sections: Section[];
}


@Module({ dynamic: true, store, name: 'sectionstore' })
class SectionStore extends VuexModule implements Sections {
  public sections = [] as Section[];

  @Action
  public setSection(sections: Section[]) {
    this.SET_SECTIONS(sections);
  }

  @Mutation
  private SET_SECTIONS(sections: Section[]) {
    this.sections = sections;
  }

}
export const SectionModule = getModule(SectionStore);
